*,
*:before,
*:after {
  box-sizing: border-box;
  user-select: none;
}

html {
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

body {
  margin: 0;
  display: flex;
  flex: 1;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 16px;
  overflow: hidden;
}

a {
  &:hover {
    text-decoration: none;
    box-decoration-break: clone;
    text-shadow: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 280%;
}

h2 {
  font-size: 200%;
}

h3 {
  font-size: 180%;
}

:global {
  #root {
    display: flex;
    flex: 1;
  }
}
